/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.bigcicle{
    background: transparent linear-gradient(
180deg, #72E691 0%, #0B8E2F 100%) 0% 0% no-repeat padding-box;
    width: 150vh;
    height: 54vh;
    margin-top: -6%;
    margin-left: -82%;
    border-radius: 50%;
    position: absolute;
    font-family: 'Lato', sans-serif;
  }
  .contiene{
    background: #DFE0E4;
    width: 100%;
    font-family: 'Lato', sans-serif;
  
  }
  .innerdrama{
    width: 80%;
    margin-left: 10%;
    min-height: 40vh;
    background-color: white;
    font-family: 'Lato', sans-serif;
    margin-top: 1vh;
    border-radius: 18px;
    box-shadow: 0px 12px 24px #00000029;
    padding:30px;
  }
  .logologin{
       width: 50%;
    margin-left: 25%;
    margin-top: 11%;
    font-family: 'Lato', sans-serif;
  }
  .insa{
    z-index: 999;
    position: absolute;
    width: 100%;
    font-family: 'Lato', sans-serif;
  }
  p{
    margin:0px;
    font-family: 'Lato', sans-serif;
  }
  .ingresa{
    font-size:19px;
    color:#505050;
    font-family: 'Lato', sans-serif;
  }
  .codex{
    font-size: 12px;
    color:#505050;
   margin-top:10px;
   font-family: 'Lato', sans-serif;
  }
  .emailhere{
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 15px;
    background-color: #EFEFEF;
    color: #505050;
    margin-top: 20px;
    font-family: 'Lato', sans-serif;
  }

  .verificalo{
    width: 100%px;
    height: 40px;
    background: #0B8E2F 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 54px;
    opacity: 1;
    margin-top:25px;
    font-family: 'Lato', sans-serif;
  }
.lopeor{
  width: 100%;
    line-height: 38px;
    text-align: center;
    color: white;
    font-family: 'Lato', sans-serif;
}

.blackangreen{
    background-color: green;
    width: 100vw;
    padding-top:20px;
    font-family: 'Lato', sans-serif;
   }
   
   .inneryout{
     width: 100%;
     min-height: 80vh;
     background-color: #F1F2F6;
     border-radius: 65px 65px 0px 0px;
     padding-left: 10%;
     padding-right: 10%;
     box-shadow: -1px -4px 29px 6px rgba(0,0,0,0.75);
 -webkit-box-shadow: -1px -4px 29px 6px rgba(0,0,0,0.75);
 -moz-box-shadow: -1px -4px 29px 6px rgba(0,0,0,0.75);
 font-family: 'Lato', sans-serif;
   }
 
   .inthedash{
     color: #505050;
     font-size: 22px;
     font-weight: 600;
     margin-bottom: 1px;
     font-family: 'Lato', sans-serif;
   }
   .spacer{  
     height:30px;
     
   }
   .unic{
     margin-top:15px;
     color:#505050;
     font-family: 'Lato', sans-serif;
   }
   .unicamente{
     margin-top: 15px;
     color: #8A8A8A;
     font-weight: 600;
     font-size: 18px;
     font-family: 'Lato', sans-serif;
   }
   .precio{
     border-radius: 11px;
     height: 39px;
     border: 0px;
     color: #505050;
     font-weight: 600;
     font-size: 22px;
     width:100%;
     font-family: 'Lato', sans-serif;
   }
   .intrantra{
     font-size: 17px;
     font-family: 'Lato', sans-serif;
     font-weight: 600;
     color: #8A8A8A;
     
   }
   .cice{
     width: 25px;
     height: 25px;
     background-color: green;
     float: right;
     border-radius: 25px;
     font-family: 'Lato', sans-serif;
   }
   .darkerred{
     background-color: darkred !important;
     margin-top:8px;
     font-family: 'Lato', sans-serif;
   }
   .lasfechas{
     margin-top:40px;
     font-family: 'Lato', sans-serif;
   }
   .conejos{
     padding: 16px;
     height: 10vh;
     font-family: 'Lato', sans-serif;
   }
   .ex{
     width: 100%;
     margin: 0px;
     text-align: center;
     line-height: 24px;
     color: white;
     font-family: 'Lato', sans-serif;
   }
   .specialx{
     margin-bottom: 9vh;
     margin-left: 10px;
     font-size: 26px;
     color: white;
     font-family: 'Lato', sans-serif;
   }

   .wt{
    width: 100%;
    height: 27vh;
    background:linear-gradient(180deg, #4FC870 0%, #18943A 100%)  ;
    border-radius: 0px 0px 0px 60px;
    box-shadow: 0px 12px 24px #00000029;
    font-family: 'Lato', sans-serif;
  }
  .conntec{
    margin-top: 10vh;
    color: white;
    font-size: 22px;
    margin-left: 16%;
    margin-bottom: 0px;
    font-family: 'Lato', sans-serif;
  }
.conntecta{
  margin-top: 8px;
    color: #b1b1b1;
    font-size: 17px;
    margin-left: 16%;
    font-family: 'Lato', sans-serif;
}
.haha{
  width: 51%;
    margin-left: 43%;
    margin-top: 7vh;
    font-family: 'Lato', sans-serif;
}
.bottoncin{
  width: 100%;
    border-radius: 7px;
    height: 25px;
    background-color: white;
    margin-top: 9%;
    font-family: 'Lato', sans-serif;
}
.bottoncin p{
  width: 100%;
  line-height:27px ;
  font-size: 12px;
  color:black;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.adonwana{
  min-height: 100vh;
  background-color:#F1F2F6 ;
  font-family: 'Lato', sans-serif;
}

.trash{
  max-width: none;
  width: 17px;
  margin-top: 3px;
  font-family: 'Lato', sans-serif;
}

.cuadra{
  background: #A8FFC0 0% 0% no-repeat padding-box;
  font-family: 'Lato', sans-serif;
    width: 86%;
    height: 132px;
    margin-top: 11%;
    margin-left: 10%;
    border-radius: 25px;
    box-shadow: 0px 12px 24px #00000029;
}
.aurora1{
  margin-top: 10px;
  font-size: 12px;
  font-weight: 800;
  font-family: 'Lato', sans-serif;
}
.aurora2{
  font-size: 12px;
  font-family: 'Lato', sans-serif;
}
.cuadrados{
  background-color: #DFE1E5 ;
    width: 86%;
    height: 132px;
    margin-top: 11%;
    margin-left: 5%;
    border-radius: 25px;
    box-shadow: 0px 12px 24px #00000029;
    font-family: 'Lato', sans-serif;
}
.lakeside{
  width: 40%;
    margin-left: 30%;
    margin-top: 19%;
    font-family: 'Lato', sans-serif;
}
.sad{
  color: #505050;
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-top: 3px;
    font-family: 'Lato', sans-serif;
}
.innerbubbles{
  width: 90%;
  min-height: 100px;
  background-color: white;
  border-radius: 25px;
  margin-left: 5%;
  box-shadow: 0px 12px 24px #00000029;
  margin-top:20px;
  font-family: 'Lato', sans-serif;
}
.pal{
    margin: 0px;
    font-family: 'Lato', sans-serif;
}
.content{
  padding: 26px 26px 4px 26px;
  font-family: 'Lato', sans-serif;
}
.content{
  padding: 12px 12px 4px 12px;
  font-family: 'Lato', sans-serif;
}
.publi{
  width:25%;
  height: 15px;
  border-radius: 20px;
  font-family: 'Lato', sans-serif;
}
.publi{
  background: transparent linear-gradient(
180deg, #4FC870 0%, #18943A 100%) 0% 0% no-repeat padding-box;
    width: 50%;
    height: 34px;
    border-radius: 20px;
    margin-bottom: 14px;
    margin-top: 10px;
    margin-left: 52%;
    font-family: 'Lato', sans-serif;
}
.publi p{
    width: 100%;
    text-align: center;
    line-height: 35px;
    color: white;
    font-family: 'Lato', sans-serif;
}

.inter{
  border-bottom: 2px solid green;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: white;
  line-height: 25px;
  height: 49px;
  width: 90%;
  margin-left: 5%;
  font-family: 'Lato', sans-serif;
}

.checkbox-round {
  width: 2.3em;
  height: 2.3em;
  background-color: #f1f2f6;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #36a22c;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-left: 12px;
  margin-top: -2px;
}

.checkbox-round:checked {
  background-color: #36a22c;
}

ion-app{

  display: flex !important;
  max-width: 408px !important; 
  margin: auto !important;

}

.blackangreen{

  max-width: 408px !important; 

}